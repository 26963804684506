import { format } from "date-fns";

/**
 * The 'from' property represents the start date at the beginning of the day (00:00:00).
 * The 'to' property represents the end date at the end of the day (23:59:59).
 */
export function RoundDateRangeToFullDays(
  start: Date | undefined,
  end: Date | undefined
) {
  if (!start || !end) return undefined;
  return {
    from: format(start, "yyyy-MM-dd'T'00:00:00.000'Z'"),
    to: format(end, "yyyy-MM-dd'T'23:59:59.999'Z'")
  };
}
